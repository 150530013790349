<template>
  <div class="member">
    <div class="member-bottom" ref="imageDom" @click="clickGeneratePicture">
      <img src="../../assets/kaifa.jpg" alt="" class="img1" />
      <p class="p1">开发中</p>
      <div class="qrcode" ref="qrCodeUrl"></div>
    </div>
    <!-- //分享海报 -->
    <div class="share" v-show="shareShow" @click="closeShare">
      <div class="share1">
        <img :src="imgUrl" alt="" @click="DownLoad" />
        <p>长按保存</p>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.member {
  width: 100%;
  height: 100%;
  .details-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
    box-sizing: border-box;
    font-size: 0.38rem;
    background: #f4f4f4;
  }
  .member-bottom {
    .img1 {
      width: 100%;
      height: 11.5rem;
    }
    .p1 {
      font-size: 1rem;
      color: #fff;
      position: absolute;
      top: 40%;
      left: 30%;
      transform: translateY(-50%);
    }
    .qrcode {
      position: absolute;
      top: 2rem;
      left: 2rem;
    }
  }
  .share {
    width: 100%;
    height: 15rem;
    background: #000;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    .share1 {
      position: absolute;
      top: 20%;
      left: 20%;
      opacity: 1;
      img {
        width: 5rem;
        height: 6.5rem;
      }
      p {
        font-size: 0.5rem;
        color: red;
      }
    }
  }
}
</style>
<script>
//生成二维码
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas"
// import $ from 'jquery'
export default {
  data() {
    return {
      imgUrl: "",
      shareShow: false,
    };
  },
  mounted() {
    // this.userinfo = JSON.parse(JSON.stringify(this.$store.state.user_info));
    // console.log(this.userinfo);

    this.creatQrCode();
  },
  methods: {
    //生成二维码
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: "123456",
        width: 50,
        height: 50,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    //转图片
    clickGeneratePicture() {
      // console.log(1111)
      html2canvas(this.$refs.imageDom).then((canvas) => {
        // 转成图片，生成图片地址
        this.shareShow = true;
        this.imgUrl = canvas.toDataURL("image/png");
        // console.log(this.imgUrl)
        backgroundColor: null; // null 表示设置背景为透明色
      });
      // html2canvas(this.$refs.imageDom, {

      // })
    },
    //关闭弹窗
    closeShare() {
      this.shareShow = false;
    },
    //点击下载本地
    DownLoad() {
      // 创建隐藏的可下载链接
      var eleLink = document.createElement("a");
      eleLink.href = this.imgUrl; // 转换后的图片地址
      eleLink.download = "pictureName";
      // 触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
      // 然后移除
      document.body.removeChild(eleLink);
    },
  },
  created() {
    document.title = "积分商城";
  },
};
</script>
